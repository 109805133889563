import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/services.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"

import RecentSuccessStories from "../../components/RecentSuccessStories"
// import Dynamics365UpgradeWhyChoose from "../../components/dynamics365UpgradeWhyChoose"
import Dynamics365UpgradeSimplified from "../../components/Dynamics365UpgradeSimplified"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"

import img1 from "../../images/D365/Retail_D365.png"
import IconsAdded from "../../components/IconsAdded"

const Index = ({ data }) => {
  return (
    <div className="dynamics-parent-class">
      <div className="dynamics365Upgrade">
        <Layout pageTitle={"Visiomate - Upgrade of Dynamics 365 Finance and Operation"}
        descrip = {"Upgrade your Dynamics 365 Finance & Operations to the latest version. Unlock new features, improve performance, and stay ahead of the curve. Learn more!"}>
          <OdooTopSection
            headerData={{
              title:
                "At Visiomate, we are dedicated to facilitating smooth business transformations with our comprehensive D365 upgrade services",
            }}
            data={data}
          /> 
          <Dynamics365OptimizationImageWithTextSection
            content={{
              heading:
                "Elevate Your Business: Unleash Growth Potential with Dynamics 365 Upgraded Solutions",
              description:
                "Make a smart move by transitioning from AX 2012 to Dynamics 365 and embracing the cloud! Our Dynamics AX 2012 to Dynamics 365 Upgrade services at Visiomate empower you to enhance agility, automate tasks, and achieve success in your digital transformation.",
              d2:
                "We assure you a seamless and successful migration, minimizing disruptions and maximizing the benefits of upgrading to Microsoft Dynamics 365.",
            }}
            data={data}
          />
          <WhyChooseVisiomateforD365
            data={{
              title: "Why Choose Visiomate for D365 Upgrade?",
              headings: [
                {
                  heading: "Crafting Tailored Solutions",
                  description:
                    "At Visiomate, our seasoned professionals recognize the distinctiveness of every business. We pride ourselves on developing bespoke D365 solutions meticulously crafted to harmonize seamlessly with your organization's goals and processes.",
                },
                {
                  heading: "Seamless End-to-End Implementation",
                  description:
                    "From the drawing board to flawless execution, our comprehensive D365 implementation services pave the way for a seamless transition. Unlock the full potential of Microsoft's robust platform, empowering your business for success.",
                },
                {
                  heading: "Proactive Support at Every Turn",
                  description:
                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. Our dedicated team ensures the continuous seamless operation of your D365 environment, offering timely assistance, updates, and a commitment to ongoing improvements.",
                },
                {
                  heading: "Navigating Industries with Expertise",
                  description:
                    "With a diverse portfolio spanning the apparel and footwear sector, Visiomate brings a wealth of industry-specific knowledge to the table. Whether you're dealing with fashion trends, inventory management, or supply chain intricacies, our expertise is tailored to elevate your operations",
                },
              ],
            }}
            dataFormImages={data}
          />
          <RecentSuccessStories
            data={{
              title: "Recent Success Stories ",
              Text: [
                {
                  description:
                    "Recently, we upgrade the Dynamics 365 Retail MPOS to all new Store Commerce for SJS International.",
                  image: img1
                },

              ],
            }}
            dataFormImages={data}
          />
          <Dynamics365UpgradeSimplified />
          <Wethoughtyoumighthavequestions
            data={[
              {
                question:
                  "What is involved in upgrading from Dynamics AX 2012 to Dynamics 365?",
                answer:
                  "Upgrading from Dynamics AX 2012 to Dynamics 365 involves migrating data, customizations, configurations, and business processes from the legacy AX environment to the new D365 platform. This typically includes assessing system readiness, preparing for the upgrade, performing data migration, validating the migrated data, and testing the upgraded system before deployment.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "Why should I consider upgrading from Dynamics AX 2012 to Dynamics 365?",
                answer:
                  "Upgrading from Dynamics AX 2012 to Dynamics 365 offers several benefits, including:",
                answer1:
                  "- Access to the latest features and functionalities available in Dynamics 365, including cloud-based deployment options, improved user experience, and enhanced capabilities for analytics, AI, and IoT.",
                answer2:
                  "- Continued support and updates from Microsoft, ensuring compliance with regulatory requirements, security patches, and performance enhancements.",
                answer3:
                  "- Integration with other Microsoft cloud services and productivity tools, such as Office 365, Power BI, and Power Platform, for a seamless and integrated ecosystem.",
                answer4:
                  "- Improved scalability, flexibility, and agility to adapt to changing business needs and requirements.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the key considerations for upgrading from Dynamics AX 2012 to Dynamics 365?",
                answer:
                  "Some key considerations for upgrading from Dynamics AX 2012 to Dynamics 365 include:",
                answer1:
                  "- Assessing system readiness and compatibility with the new D365 platform.",
                answer2:
                  "- Planning for data migration, including cleansing, mapping, and transformation of data from AX 2012 to D365.",
                answer3:
                  "- Evaluating customizations and extensions to determine their impact on the upgrade process and compatibility with D365.",
                answer4:
                  "- Training and readiness for users and administrators to adapt to the new features, interface, and workflows in Dynamics 365.",
                answer5:
                  "- Testing and validation of the upgraded system to ensure functionality, performance, and data integrity meet expectations.",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the different upgrade paths available for moving from Dynamics AX 2012 to Dynamics 365?",
                answer:
                  "There are several upgrade paths available for moving from Dynamics AX 2012 to Dynamics 365, including:",
                answer1:
                  "- Data Migration: Migrating data from AX 2012 to D365 using data migration tools, templates, and methodologies provided by Microsoft or third-party vendors.",
                answer2:
                  "- Reimplementation: Reimplementing business processes and configurations in D365 based on AX 2012 functionality, best practices, and industry standards.",
                answer3:
                  "- Upgrade Services: Engaging with Microsoft or certified partners to provide upgrade services, including assessment, planning, execution, and validation of the upgrade process.",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How long does it take to upgrade from Dynamics AX 2012 to Dynamics 365?",
                answer:
                  "The duration of the upgrade process from Dynamics AX 2012 to Dynamics 365 can vary depending on factors such as the complexity of the AX environment, the volume of data, the extent of customizations, and organizational readiness. Typically, upgrades can take several months to a year or more, with careful planning, execution, and testing to ensure a successful transition to D365.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What support and resources are available for organizations upgrading from Dynamics AX 2012 to Dynamics 365?",
                answer:
                  "Microsoft offers various support and resources for organizations upgrading from Dynamics AX 2012 to Dynamics 365, including:",
                answer1:
                  "- Technical Documentation: Access to upgrade guides, release notes, and technical documentation to help organizations understand the upgrade process and requirements.",
                answer2:
                  "- Upgrade Tools and Resources: Tools, utilities, and resources provided by Microsoft or third-party vendors to assist with data migration, code upgrade, and customization conversion.",
                answer3:
                  "- Consulting Services: Support and guidance from Microsoft Certified Partners, consultants, and solution providers with expertise in Dynamics AX upgrades and D365 migrations.",
                answer4:
                  "- Community Support: Online forums, user groups, and communities where organizations can seek advice, share experiences, and collaborate with peers and experts who have undergone similar upgrade journeys.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the benefits of partnering with a Microsoft Certified Partner for the upgrade process?",
                answer:
                  "Partnering with a Microsoft Certified Partner for the upgrade process offers several benefits, including:",
                answer1:
                  "- Expertise and Experience: Access to consultants and solution architects with specialized skills and experience in Dynamics AX upgrades and D365 migrations.",
                answer2:
                  "- Best Practices: Guidance and best practices based on industry standards, Microsoft recommendations, and lessons learned from previous upgrade projects.",
                answer3:
                  "- Faster Time to Value: Accelerated deployment and reduced risk of issues or delays with the upgrade process, resulting in faster time to value and return on investment.",
                answer4:
                  "- Continued Support: Ongoing support, training, and assistance from the partner throughout the upgrade process and beyond, ensuring a smooth transition to Dynamics 365.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
            ]}
            title="We thought you might have questions"
          />
          <News isAbout={true} career={true} />
        </Layout>
      </div>
    </div>
  )
}

export default Index
export const query = graphql`
  query upgradeImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Maskgroupcopy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    RSSImages1: file(relativePath: { eq: "D365/Retail_D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    RSSImages2: file(relativePath: { eq: "DistributionD365/dist_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: {
        eq: "two-male-colleagues-office-standing-with-laptop 1.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
